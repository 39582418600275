@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "Nolan Next";
    src: url("./assets/fonts/nolan-next/Nolan Next Regular.otf") format("opentype");
}

@font-face {
    font-family: "Nolan Next ExtraBold";
    src: url("./assets/fonts/nolan-next/Nolan Next ExtraBold.otf");
}

@font-face {
    font-family: "Nolan Next Medium";
    src: url("./assets/fonts/nolan-next/Nolan Next Medium.otf");
}

@font-face {
    font-family: "Nolan Next Bold";
    src: url("./assets/fonts/nolan-next/Nolan Next Bold.otf");
}

* {
    font-family: "Nolan Next";
    box-sizing: border-box;
    padding: 0px 0px;
    margin: 0px 0px;
}
.space-home{
    letter-spacing: 0.15em;
}
.space-portfolio{
    letter-spacing: 0.2em;
}
.font-b{
    font-family:Nolan Next ExtraBold !important;
    font-weight: 800 ;
}
.font-m{
    font-family:Nolan Next Medium;
    font-weight: 500;
}
.font-t{
    font-family:Nolan Next;
    font-weight:400;
}  
.porfolio-description{
    line-height: 28px;
}
.home-banner{
    background-image: url("./assets/images/wave-main-image.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.our-services{
    background-image: url("./assets/images/our-services-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-darkVoilet{
    background-color: #3B1A74 !important;
}